<template>
  <header>
      <!-- Header Start -->
      <div class="header-area">
        <div class="main-header">
          <div class="header-top top-bg d-none d-lg-block">
            <div class="container">
              <div class="row justify-content-between align-items-center">
                <div class="col-lg-8">
                  <div class="header-info-left">
                    <ul>
                      <li>disparekraf@konaweselatankab.go.id</li>
                      <li>666 569 025077</li>
                      <li>jl. Poros Andoolo-Kendari (Kompleks Perkantoran Pemda Konsel)</li>
                    </ul>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="header-info-right f-right">
                    <ul class="header-social">
                      <li>
                        <a href="#"><i class="fab fa-twitter"></i></a>
                      </li>
                      <li>
                        <a href="#"><i class="fab fa-linkedin-in"></i></a>
                      </li>
                      <li>
                        <a href="#"><i class="fab fa-facebook-f"></i></a>
                      </li>
                      <li>
                        <a href="#"><i class="fab fa-pinterest-p"></i></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="header-bottom header-sticky">
            <div class="container">
              <div class="row align-items-center">
                <!-- Logo -->
                <div class="col-xl-2 col-lg-2 col-md-1">
                  <div class="logo">
                    <a href="index.html">
                      <img src="assets/img/SMALL/logo.png" alt="" style="width:150px"/>
                    </a>
                  </div>
                </div>
                <div class="col-xl-10 col-lg-10 col-md-10">
                  <!-- Main-menu -->
                  <div class="main-menu f-right d-none d-lg-block">
                    <nav>
                      <ul id="navigation">
                        <li><router-link to="/">Home</router-link></li>
                        <li><router-link to="/destinasi">Destinasi</router-link></li>
                        <li><router-link to="/ekokraf">Ekokraf</router-link></li>
                        <li><router-link to="/kegiatan">Kegiatan</router-link></li>
                        <!-- <li><router-link to="/kalenderEvent">Event</router-link></li>
                        <li><router-link to="/penginapan">Penginapan</router-link></li> -->
                        <li>
                        <a to="javascript:void(0)">Gallery</a>
                        <ul class="submenu">
                          <li><router-link to="/foto">Photo</router-link></li>
                          <li><router-link to="/video">Video</router-link></li>
                        </ul>
                      </li>
                        <!-- <li>
                          <a href="javascript:void(0)">Pariwisata</a>
                          <ul class="submenu">
                            <li class="marginMobile"><a href="javascript:void(0)">Destinasi</a></li>
                          </ul>
                        </li> -->

                        
                        <!-- <li>
                          <a href="javascript:void(0)">Blog</a>
                          <ul class="submenu">
                            <li><a href="javascript:void(0)">Blog</a></li>
                            <li >
                                <a @click="toggleMenu('ubahSajaIni')" class="trig_submenu" href="javascript:void(0)">
                                    <div class="item_trig_submenu">Menu</div>
                                    <div class="sideIcon">+</div>
                                </a> 
                                    <div class="hideMobile"></div>
                                    <ul class="submenu1" id="ubahSajaIni">
                                        <li> 
                                            <div class="showMobile"><br></div>
                                            <a href="javascript:void(0)">Pertama</a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0)">Kedua</a>
                                        </li>
                                    </ul>
                            </li>

                            <li class="marginMobile"><a href="javascript:void(0)">Blog Details</a></li>
                          </ul>
                        </li> -->

                        <li><router-link to="/about">About US</router-link></li>

                        <!-- <li>
                          <a href="javascript:void(0)">Pages</a>
                          <ul class="submenu">
                            <li><a href="javascript:void(0)">Element</a></li>
                          </ul>
                        </li> -->


                      </ul>
                    </nav>
                  </div>
                </div>
                <!-- Mobile Menu -->
                <div class="col-12">
                  <div class="mobile_menu d-block d-lg-none"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Header End -->
    </header>
</template>

<script>
export default {
    data() {
        return {
            // key: value
        }
    },
    methods: {
        toggleMenu(id) {
            var x = document.getElementById(id);
            if (x.style.display === "none") {
                x.style.display = "block";
            } else {
                x.style.display = "none";
            }

        }
    },
    mounted () {

        // var hideSubmenu = document.getElementsByClassName('submenu1')
        // hideSubmenu.style.display = "block";

        $( document ).ready(function() {
            console.log( "ready!" );
            var menu = $('ul#navigation');
            if(menu.length){
                menu.slicknav({
                    prependTo: ".mobile_menu",
                    closedSymbol: '+',
                    openedSymbol:'-'
                });
            };
            $(".submenu1").hide();
        });







         

    },

}
</script>

<style>

</style>