<template>
  <div class="home">
    <!-- slider Area Start-->
        <div class="slider-area ">

            <img src="img/baner2.png" class="img-fluid" alt="" style="width:100%">
            <!-- Mobile Menu -->
            <!-- <div class="slider-active">
                <div class="single-slider hero-overly  slider-height d-flex align-items-center bg-1">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-9 col-lg-9 col-md-9">
                                <div class="hero__caption text-center">
                                    <h1>Find your <span>Next tour!</span> </h1>
                                    <p>Kabupaten Konawe Selatan - Sulawesi Tenggara</p>
                                </div>
                            </div>
                        </div>
              
                        <div class="row">
                            <div class="col-xl-12">
                       
                                <form action="#" class="search-box">
                                    <div class="input-form mb-30">
                                        <input type="text" placeholder="When Would you like to go ?">
                                    </div>
                                    <div class="search-form mb-30">
                                        <a href="#">Search</a>
                                    </div>	
                                </form>	
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
        <!-- slider Area End-->
        <!-- Our Services Start -->
        <!-- <div class="our-services servic-padding">
            <div class="container">
                
                <div class="row d-flex justify-contnet-center">
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6">
                        <div class="single-services text-center mb-30">
                            <div class="services-ion">
                                <span class="flaticon-tour"></span>
                            </div>
                            <div class="services-cap">
                                <h5>10+ Our Local<br>Guides</h5>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6">
                        <div class="single-services text-center mb-30">
                            <div class="services-ion">
                                <span class="flaticon-pay"></span>
                            </div>
                            <div class="services-cap">
                                <h5>100% Trusted Tour<br>Agency</h5>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6">
                        <div class="single-services text-center mb-30">
                            <div class="services-ion">
                                <span class="flaticon-experience"></span>
                            </div>
                            <div class="services-cap">
                                <h5>28+ Years of Travel<br>Experience</h5>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6">
                        <div class="single-services text-center mb-30">
                            <div class="services-ion">
                                <span class="flaticon-good"></span>
                            </div>
                            <div class="services-cap">
                                <h5>98% Our Travelers<br>are Happy</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
         <!-- Our Services End -->
        <!-- Favourite Places Start -->


        <div class="favourite-place place-padding" style="background:#EEEEEE; margin-top:-3%">
            <div class="container">
                 <!-- Section Tittle -->
                <div class="row">
                    <div class="col-lg-12">
                        <div class="section-tittle text-center">
                            <span>FEATURED TOURS</span>
                            <h2>Destinasi Unggulan</h2>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-4 col-lg-4 col-md-6" v-for="data in list_wisata" :key="data.id">
                        <div class="single-place mb-30">
                            <div class="place-img">
                                <img :src="$store.state.url.URL_APP+'uploads/'+data.fileThumb" alt="">
                            </div>
                            <div class="place-cap">
                                <div class="place-cap-top">
                                    <!-- <span><i class="fas fa-star"></i><span>8.0 Superb</span> </span> -->
                                    <h3><a href="javascript:void(0)" @click="routerToPlace('/wisataDetile?idx='+data.keyx)">{{data.uraian}}</a></h3>
                                    <p class="dolor"><span><i class="far fa-calendar"></i>{{UMUM.tglConvert(data.createdAt).tgl}}</span></p>
                                </div>
                                <div class="place-cap-bottom">
                                    <ul>
                                        <!-- <li><i class="far fa-calendar"></i>{{UMUM.tglConvert(data.createdAt).tgl}}</li> -->
                                        <li><i class="fas fa-map-marker-alt"></i>{{data.nama_des_kel}}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Favourite Places End -->
        <!-- Video Start Arera -->
        <div class="video-area video-bg pt-200 pb-200 bg-2">
            <div class="container">
                <div class="row">
                    <div class="col-xl-12">
                        <div class="video-caption text-center">
                            <div class="video-icon">
                                <a class="popup-video" href="https://www.youtube.com/watch?v=xqh4hcYn8L0" tabindex="0"><i class="fas fa-play"></i></a>
                            </div>
                            <p class="pera2">DESA WISATA NAMU</p>
                            <p class="pera1">Dengan hamparan pasir putih yang terjaga</p>
                            <!-- <p class="pera3"> Tour Booking Platform</p> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Video Start End -->
        <!-- Support Company Start-->
        <div class="support-company-area support-padding fix">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-xl-6 col-lg-6">
                        <div class="support-location-img mb-50">
                            <img src="assets/img/service/support-img.jpg" alt="">
                            <div class="support-img-cap">
                                <span>DISPAREKRAF</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6">
                        <div class="right-caption">
                            <!-- Section Tittle -->
                            <div class="section-tittle section-tittle2">
                                <span>Disparekraf Konsel</span>
                                <h2>Desa Maju <br>Konsel Hebat</h2>
                            </div>
                            <div class="support-caption">
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud</p>
  
                                <a href="#" class="btn border-btn">About us</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Support Company End-->
        <!-- Testimonial Start -->
        <!-- Testimonial Start -->
        <div class="testimonial-area testimonial-padding bg-3">
            <div class="container ">
                <div class="row d-flex justify-content-center">
                    <div class="col-xl-11 col-lg-11 col-md-9">
                        <div class="h1-testimonial-active">
                            <!-- Single Testimonial -->
                            <div class="single-testimonial text-center">
                                <!-- Testimonial Content -->
                                <div class="testimonial-caption ">
                                    <div class="testimonial-top-cap">
                                        <img src="assets/img/icon/testimonial.png" alt="">
                                        <p>Logisti Group is a representative logistics operator providing full range of ser
                                            of customs clearance and transportation worl.</p>
                                    </div>
                                    <!-- founder -->
                                    <div class="testimonial-founder d-flex align-items-center justify-content-center">
                                        <div class="founder-img">
                                            <img src="assets/img/testmonial/Homepage_testi.png" alt="">
                                        </div>
                                        <div class="founder-text">
                                            <span>Jessya Inn</span>
                                            <p>Co Founder</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Testimonial End -->
        <!-- Blog Area Start -->
        <div class="home-blog-area section-padding2">
            <div class="container">
                <!-- Section Tittle -->
                <div class="row">
                    <div class="col-lg-12">
                        <div class="section-tittle text-center">
                            <span>Our Recent news</span>
                            <h2>Kegiatan Terbaru</h2>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-6 col-lg-6 col-md-6" v-for="data in list_kegiatan" :key="data.id">
                        <div class="home-blog-single mb-30">
                            <div class="blog-img-cap">
                                <div class="blog-img">
                                    <img :src="$store.state.url.URL_APP+'uploads/'+data.foto" alt="">
                                </div>
                                <div class="blog-cap">
                                    <h3><a href="javascript:void(0)" @click="routerToPlace('/kegiatanDetile?idx='+data.keyx)">{{data.nama}}</a></h3>
                                    <a href="javascript:void(0)" @click="routerToPlace('/kegiatanDetile?idx='+data.keyx)" class="more-btn">Read more »</a>
                                </div>
                            </div>
                            <div class="blog-date text-center">
                                <span>{{UMUM.tglConvert(data.createAt).tglx}}</span>
                                <p>{{UMUM.tglConvert(data.createAt).blnx}}</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <!-- Blog Area End -->
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import UMUM from "../library/umum";

export default {
  name: 'Home',
  components: {
    HelloWorld
  },
  data() {
      return {
          key: '',
          list_wisata : '',
          list_kegiatan : [],
          UMUM : UMUM
      }
  },
  methods: {
      getView : function(){
        // this.$store.commit("shoWLoading");
        fetch(this.$store.state.url.URL_WISATA_OBJEK + "home_view", {
            method: "POST",
            headers: {
              "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify({
                data: '',
            })
        })
            .then(res => res.json())
            .then(res_data => {
            //   console.log(res_data)
              this.list_wisata = res_data;
        });
      },

      getViewKegiatan : function(){
        // this.$store.commit("shoWLoading");
        fetch(this.$store.state.url.URL_KEGIATAN + "home_view", {
            method: "POST",
            headers: {
              "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify({
                limit: 2,
            })
        })
            .then(res => res.json())
            .then(res_data => {
              console.log(res_data)
              this.list_kegiatan = res_data

        });
      },

      routerToPlace(data){
            // console.log('telasooo')
            this.$router.push(data);
      },
  },

  mounted () {
      this.getView();
      this.getViewKegiatan();
  },
}
</script>
