import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'


import headers from './components/headers.vue'
Vue.component('headers', headers);

import footers from './components/footers.vue'
Vue.component('footers', footers);

import destinasiLainnya from './components/destinasiLainnya.vue'
Vue.component('destinasiLainnya', destinasiLainnya);

import fotoLainnya from './components/fotoLainnya.vue'
Vue.component('fotoLainnya', fotoLainnya);

import kegiatanLainnya from './components/kegiatanLainnya.vue'
Vue.component('kegiatanLainnya', kegiatanLainnya);



Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
