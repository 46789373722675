// import {store1} from '../store/index'

var DataStore = require('../store');
var store = DataStore.default


async function FETCH_POST (url, body){
  return new Promise(resolve =>{
    fetch(url, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        authorization: "kikensbatara " + localStorage.token
      },
      body: JSON.stringify(body)
    })
      .then(res => res.json())
      .then(res_data => {
        // console.log(res_data)
        resolve(res_data)
      });
  })
}





module.exports = {
  FETCH_POST: FETCH_POST,
}