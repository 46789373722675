<template>
  <aside class="single_sidebar_widget popular_post_widget">
    <h3 class="widget_title">Destinasi Lainnya</h3>

    <div class="media post_item" v-for="data in list_data" :key="data.id">
      <img style="width:75px" :src="$store.state.url.URL_APP+'uploads/'+data.fileThumb" alt="post" />


      <div class="media-body">
        <a :href="protocolx+'//'+host+'/wisataDetile?idx='+data.keyx">


        <!-- href="javascript:void(0)" @click="routerToPlace('/wisataDetile?idx='+data.keyx)" -->
          <h3>{{data.uraian}}</h3>
        </a>
        <p>{{UMUM.tglConvert(data.createdAt).tgl}}</p>
      </div>
    </div>




  </aside>
</template>

<script>
import FETCHING from  '../library/fetching'
import UMUM from  '../library/umum'


export default {
  data() {
    return {
      list_jenis_wisata : [],
      bidangPariwisata : 2,


      list_data: [],
      page_first: 1,
      page_last: 0,
      cari_value: "",
      page_limit : 9,

      m_jenisPariwisata : '',

      FETCHING : FETCHING,
      UMUM : UMUM,
      host : '',
      protocolx : '',


      loadJenis : false
    }
  },

  methods: {
    getView: function() {
      fetch(this.$store.state.url.URL_WISATA_OBJEK + "home_view", {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          m_jenisPariwisata : this.m_jenisPariwisata,
          data_ke: this.page_first,
          cari_value: this.cari_value,
          page_limit : this.page_limit,
        })
      })
        .then(res => res.json())
        .then(res_data => {
          this.list_data = res_data
            // console.log(res_data);
        });
    },


    btn_prev: function() {
      if (this.page_first > 1) {
          this.page_first--;
      } else {
          this.page_first = 1;
      }
      this.getView();
    },

    btn_next: function() {
      if (this.page_first >= this.page_last) {
          this.page_first == this.page_last;
      } else {
          this.page_first++;
      }
      this.getView();
    },

    cari_data: function() {
      this.page_first = 1;
      this.getView();
    },

    fetcher : async function() {
      this.loadJenis = false
      this.list_jenis_wisata = await FETCHING.FETCH_POST(
        this.$store.state.url.URL_JENIS_WISATA + "list", 
        {bidangPariwisata : this.bidangPariwisata}
      )

      this.loadJenis = true

      // console.log(this.list_jenis_wisata)
    },
    routerToPlace(data){
            // console.log('telasooo')
            // this.$router.push(data);

            try {
        this.$router.push(data);
    } catch (error) {
       if (!(error instanceof NavigationDuplicated)) {
           throw error;
       }
    }


    },
  },

  mounted () {
    this.fetcher()
    this.getView();

    this.host = window.location.host;
    this.protocolx = window.location.protocol

    console.log(this.protocolx)
    // console.log(pathname); // blog
  },


};
</script>

<style>
</style>