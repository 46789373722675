<template>
  <aside class="single_sidebar_widget popular_post_widget">
    <h3 class="widget_title">Kegiatan Lainnya</h3>

    <div class="media post_item" v-for="data in list_data" :key="data.id">
      <img  style="width:75px" :src="$store.state.url.URL_APP+'uploads/'+data.foto" alt="post" />
      <div class="media-body">
        <a :href="protocolx+'//'+host+'/kegiatanDetile?idx='+data.keyx">
          <h3>{{data.nama}}</h3>
        </a>
        <p>{{UMUM.tglConvert(data.createAt).tgl}}</p>
      </div>
    </div>

  </aside>
</template>


<script>

import UMUM from "../library/umum";

export default {
    data() {
        return {
            form : {
                id: "",
                nama: "",
                deskripsi: "",
                sumber : '',
                userId : '',
                tgl: "",

                file: null,
            },

            UMUM : UMUM,

            list_data : [],
            fileOld : '',

            page_first: 1,
            page_last: 0,

            host : '',
            protocolx : '',



            page_limit : 6,
            cari_value: "",
        }
    },

    methods: {
        getView : function(){
            // this.$store.commit("shoWLoading");
            fetch(this.$store.state.url.URL_KEGIATAN + "view", {
                method: "POST",
                headers: {
                "content-type": "application/json",
                authorization: "kikensbatara " + localStorage.token
                },
                body: JSON.stringify({
                    data_ke: this.page_first,
                    cari_value: this.cari_value,
                    page_limit : this.page_limit,
                })
            })
                .then(res => res.json())
                .then(res_data => {
                console.log(res_data)
                this.list_data = res_data.data;
                this.page_last = res_data.jml_data;
            });
        },

        btn_prev: function() {
            if (this.page_first > 1) {
                this.page_first--;
            } else {
                this.page_first = 1;
            }
            this.getView();
        },

        btn_next: function() {
            if (this.page_first >= this.page_last) {
                this.page_first == this.page_last;
            } else {
                this.page_first++;
            }
            this.getView();
        },

        cari_data: function() {
            this.page_first = 1;
            this.getView();
        },

        routerToPlace(data){
            // console.log('telasooo')
            this.$router.push(data);
        },
    },

    mounted () {
        this.getView()

        this.host = window.location.host;
        this.protocolx = window.location.protocol

        console.log(this.protocolx)
    },
}
</script>

<style>

</style>