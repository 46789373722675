// import {store1} from '../store/index'

var DataStore = require('../store');
var store = DataStore.default


const replaceEscapeString = (text) =>{
    var datax = unescape(text)
    // console.log(datax)
    return datax
  }

  formatMoney = (num)=>{

    if (num== undefined || num== null || num== '' || num== 0) {
      return 0
    } else {
      var p = num.toFixed(2).split(".");
        return "" + p[0].split("").reverse().reduce(function(acc, num, i, orig) {
            return  num=="-" ? acc : num + (i && !(i % 3) ? "." : "") + acc;
        }, "") + "," + p[1];
  
    }
  
  }


  function tambahNol(x){
    // y=(x>9)?(x>99)?x:'0'+x:'00'+x;
    y=(x>9)?(x>99)?x:''+x:'0'+x;
    return y;
 }


  const tglConvert = (tgl) => {

    var date = new Date(tgl);
    var getBulan = date.getMonth() + 1; var bulan = '';
    if (getBulan == '1') {bulan = 'Jan'} 
    else if(getBulan == '2') {bulan = 'Feb'}
    else if(getBulan == '3') {bulan = 'Mar'}
    else if(getBulan == '4') {bulan = 'Apr'}
    else if(getBulan == '5') {bulan = 'Mei'}
    else if(getBulan == '6') {bulan = 'Jun'}
    else if(getBulan == '7') {bulan = 'Jul'}
    else if(getBulan == '8') {bulan = 'Agt'}
    else if(getBulan == '9') {bulan = 'Sep'}
    else if(getBulan == '10') {bulan = 'Okt'}
    else if(getBulan == '11') {bulan = 'Nov'}
    else if(getBulan == '12') {bulan = 'Des'}

    var minutes = 1000 * 60;
    var hours = minutes * 60;

    return {
        tgl : date.getDate() + " " + bulan + " " + date.getFullYear(),
        jam : date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds(),

        tglx : tambahNol(date.getDate()),
        blnx : bulan,
        thnx : date.getFullYear(),
    }
}
  


module.exports = {
    replaceEscapeString: replaceEscapeString,
    formatMoney : formatMoney,
    tglConvert : tglConvert
}