import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

export default new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: function () {
        return import('../views/Home.vue')
      }
    },
    {
      path: '/destinasi',
      name: 'destinasi',
      component: function () {
        return import('../views/destinasi/wisata.vue')
      }
    },
    {
      path: '/wisataDetile',
      name: 'wisataDetile',
      component: function () {
        return import('../views/destinasi/wisataDetile.vue')
      }
    },
    {
      path: '/ekokraf',
      name: 'ekokraf',
      component: function () {
        return import('../views/ekokraf/ekokraf.vue')
      }
    },
    {
      path: '/kalenderEvent',
      name: 'kalenderEvent',
      component: function () {
        return import('../views/event_wisata/kalenderEvent.vue')
      }
    },
    {
      path: '/foto',
      name: 'foto',
      component: function () {
        return import('../views/gallery/foto.vue')
      }
    },
    {
      path: '/video',
      name: 'video',
      component: function () {
        return import('../views/gallery/video.vue')
      }
    },
    {
      path: '/kegiatan',
      name: 'kegiatan',
      component: function () {
        return import('../views/kegiatan/kegiatan.vue')
      }
    },
    {
      path: '/kegiatanDetile',
      name: 'kegiatanDetile',
      component: function () {
        return import('../views/kegiatan/kegiatanDetile.vue')
      }
    },
    {
      path: '/penginapan',
      name: 'penginapan',
      component: function () {
        return import('../views/penginapan/penginapan.vue')
      }
    },
    {
      path: '/about',
      name: 'About',
      component: function () {
        return import('../views/About.vue')
      }
    }
  ]
})





// const routes = [
//   {
//     path: '/',
//     name: 'Home',
//     component: Home
//   },
//   {
//     path: '/destinasi',
//     name: 'destinasi',
//     component: function () {
//       return import('../views/destinasi/wisata.vue')
//     }
//   },
//   {
//     path: '/kalenderEvent',
//     name: 'kalenderEvent',
//     component: function () {
//       return import('../views/event_wisata/kalenderEvent.vue')
//     }
//   },
//   {
//     path: '/foto',
//     name: 'foto',
//     component: function () {
//       return import('../views/gallery/foto.vue')
//     }
//   },
//   {
//     path: '/video',
//     name: 'video',
//     component: function () {
//       return import('../views/gallery/video.vue')
//     }
//   },
//   {
//     path: '/kegiatan',
//     name: 'kegiatan',
//     component: function () {
//       return import('../views/kegiatan/kegiatan.vue')
//     }
//   },
//   {
//     path: '/penginapan',
//     name: 'penginapan',
//     component: function () {
//       return import('../views/penginapan/penginapan.vue')
//     }
//   },
//   {
//     path: '/about',
//     name: 'About',
//     component: function () {
//       return import('../views/About.vue')
//     }
//   }
// ]

// const router = new VueRouter({
//   mode: 'history',
//   routes
// })

// export default router
