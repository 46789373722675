import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

// var URL = 'http://localhost:5014/';

var URL = 'https://server.visit-konsel.konaweselatankab.go.id/';

export default new Vuex.Store({
  state: {
    url : {
      URL_APP: URL,
      URL_JENIS_WISATA : URL + 'api/v1/c_jenisPariwisata/',
      URL_WISATA_OBJEK : URL + 'api/v1/c_wisataobjek/',
      URL_WISATA_OBJEK_FASILITAS : URL + 'api/v1/c_wisataobjekfasilitas/',
      URL_EKOKRAF_PELAKU_PRODUK : URL + 'api/v1/c_ekokrafPelakuProduk/',

      URL_FOTO : URL + 'api/v1/c_foto/',
      URL_VIDEO : URL + 'api/v1/c_video/',
      URL_KEGIATAN : URL + 'api/v1/c_kegiatan/',
    }
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
